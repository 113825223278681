import { supabase } from "./supabase";

export async function getTotalUsersCount() {
  const { data, error } = await supabase.rpc("get_unique_user_count", {
    passw: "2ny8721ye3n872y",
  });
  if (error) {
    console.error("Errore:", error);
    return null;
  }

  return data;
}
export async function getLogsSup() {
  const { data, error } = await supabase
    .from("logs")
    .select("*")
    .order("id", { ascending: false })
    .limit(200);

  return data;
}
export async function getTransactions() {
  const { data, error } = await supabase
    .from("transactions")
    .select("*")
    .order("id", { ascending: false })
    .limit(300);

  return data;
}
