import { initializeApp } from "firebase/app";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";
import {
  doc,
  getFirestore,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  getAggregateFromServer,
  sum,
  getCountFromServer,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { BaseData } from "./util/const";
import { getPriceToken } from "./util/util";

const config = {
  apiKey: "AIzaSyDDLIBhXcU51NhIxgKfsdYRSofLhXDfVBg",
  authDomain: "html5-gaming-bot.firebaseapp.com",
  databaseURL: "https://html5-gaming-bot-default-rtdb.firebaseio.com",
  projectId: "html5-gaming-bot",
  storageBucket: "html5-gaming-bot.appspot.com",
  messagingSenderId: "150263918272",
  appId: "1:150263918272:web:8fbb1aae9b0fd0716d4725",
  measurementId: "G-13M8CVMBG8",
};

const app = initializeApp(config);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const logOut = async () => {
  await signOut(auth);
};

export async function getStats() {
  const statRef = doc(db, "/aBase/stats");
  const statDoc = await getDoc(statRef);

  return statDoc.data();
}
export async function getReferralLead() {
  const refRef = collection(db, "referrals");
  const q = query(
    refRef,
    where("username", "!=", null),
    orderBy("numReferrals", "desc"),
    limit(50)
  );
  const refSnaps = await getDocs(q);

  let refs = [];

  refSnaps.docs.forEach((d) => {
    refs.push({
      ...d.data(),
      uid: d.id,
    });
  });

  return refs;
}
export async function getReferralPartnerLead() {
  const refRef = collection(db, "referrals");
  const q = query(
    refRef,
    where("type", "==", "partner"),
    orderBy("numReferrals", "desc"),
    limit(30)
  );
  const refSnaps = await getDocs(q);

  let refs = [];

  refSnaps.docs.forEach((d) => {
    refs.push({
      ...d.data(),
      uid: d.id,
    });
  });

  return refs;
}
export async function getTasksLead() {
  const refRef = collection(db, "users-tasks");
  const q = query(refRef, orderBy("num_completed", "desc"), limit(30));
  const refSnaps = await getDocs(q);

  let refs = [];

  refSnaps.docs.forEach((d) => {
    refs.push({
      ...d.data(),
      uid: d.id,
    });
  });

  return refs;
}
export async function getLogs() {
  const refRef = collection(db, "logs");
  const q = query(refRef, orderBy("date", "desc"), limit(100));
  const refSnaps = await getDocs(q);

  let refs = [];

  refSnaps.docs.forEach((d) => {
    refs.push({
      ...d.data(),
      id: d.id,
    });
  });

  return refs;
}
export async function getPointsInfo() {
  const refRef = collection(db, "user-points");

  let summary = {
    tot_now: 0,
    tot_ever: 0,
    tot_partner: 0,
    tot_social_media: 0,
    tot_ads: 0,
  };

  let promis = [];
  promis.push(
    getAggregateFromServer(refRef, {
      points: sum("points"),
    }).then((d) => (summary.tot_now = d.data().points))
  );
  promis.push(
    getAggregateFromServer(refRef, {
      points: sum("total_points"),
    }).then((d) => (summary.tot_ever = d.data().points))
  );
  getAggregateFromServer(refRef, {
    points: sum("points_ads"),
  }).then((d) => (summary.tot_ads = d.data().points));
  promis.push(
    getAggregateFromServer(refRef, {
      points: sum("points_partner"),
    }).then((d) => (summary.tot_partner = d.data().points))
  );
  promis.push(
    getAggregateFromServer(refRef, {
      points: sum("points_social_media_eng"),
    }).then((d) => (summary.tot_social_media = d.data().points))
  );

  await Promise.all(promis);

  return summary;
}

export async function getTotTgIdsCount() {
  const allU = await getDoc(doc(db, "aBase/allUsers"));
  return Object.keys(allU.data()).length;
}

let baseDataCache = null;
export async function getBaseData() {
  if (baseDataCache) return baseDataCache;

  const bd = (await getDoc(doc(db, "aBase", "baseInfo"))).data();

  baseDataCache = bd;
  return bd;
}
export async function getStatusFb() {
  const bd = (await getDoc(doc(db, "aBase", "status"))).data();

  baseDataCache = bd;
  return bd;
}
export async function getP2ELeaderboards() {
  const leadsSnap = await getDocs(collection(db, "leaderboards"));

  let leadDocs = [];
  for (const gameActive of BaseData.baseInfo.p2eGames.games_active) {
    const d = await getDoc(doc(db, "leaderboards/" + gameActive.url));
    leadDocs.push(d);
  }
  let leads = [];

  for (const d of leadsSnap.docs) {
    const totPlayers = await getCountFromServer(
      collection(db, "leaderboards", d.id, "scores")
    );
    leads.push({
      gameUrl: d.id,
      tot_players: totPlayers.data().count,
      ...d.data(),
      top50: Object.entries(d.data()?.top50 ?? {})
        .map(([uid, d], i) => ({
          ...d,
          uid,
        }))
        .sort((a, b) => {
          if (b.score !== a.score) {
            return b.score - a.score;
          }
          return new Date(a.date) - new Date(b.date);
        })
        .map((item, index) => ({
          ...item,
          rank: index + 1, // Assegna il rank progressivo
        })),
    });
  }
  return leads;
}

export async function getTotWallets() {
  let balances = [
    { label: "eth", symbol: "eth", amount: 0 },
    { label: "sol", symbol: "sol", amount: 0 },
    { label: "usdt", symbol: "usdt", amount: 0 },
    { label: "usdc", symbol: "usdc", amount: 0 },
    { label: "ton", symbol: "ton", amount: 0 },
    { label: "ghub", symbol: "ghub", amount: 0 },
  ];
  const userRef = collection(db, "users");
  let promis = [];
  balances.forEach((b) => {
    promis.push(
      getAggregateFromServer(userRef, {
        s: sum(`balances.${b.symbol}.amount`),
      }).then((d) => {
        balances.find((a) => a.symbol === b.symbol).amount = d.data().s;
      })
    );
  });

  await Promise.all(promis);
  const subEthIds = ["6038960372", "1447757615", "633997410"];
  let ethsToRemove = 0;
  for (const i of subEthIds) {
    const bal = (await getDoc(doc(db, `users/${i}`))).data().balances.eth
      .amount;
    ethsToRemove += bal;
  }
  balances.find((z) => z.symbol === "eth").amount -= ethsToRemove;

  for (const b of balances) {
    const usdPirce = await getPriceToken(b.symbol);
    b.usd = usdPirce * b.amount;
  }
  balances.unshift({
    label: "total no ghub",
    symbol: "$",
    amount: balances.reduce(
      (sum, balance) => (balance.symbol !== "ghub" ? sum + balance.usd : sum),
      0
    ),
    usd: balances.reduce(
      (sum, balance) => (balance.symbol !== "ghub" ? sum + balance.usd : sum),
      0
    ),
  });

  return balances;
}

export async function getWaitingManualWith() {
  const withRef = collection(db, "withdrawals");
  const q = query(withRef, where("needManual", "==", true));
  const res = await getDocs(q);

  return res.docs.map((d) => ({ ...d.data(), id: d.id }));
}
/* export async function getWaitingCodeWith() {
  const withRef = collection(db, "withdrawals");
  const q = query(withRef, where("status", "==", "CREATING"));
  const res = await getDocs(q);

  return res.docs.map((d) => ({ ...d.data(), id: d.id }));
} */
export async function getWaitingSubmitWith() {
  const withRef = collection(db, "withdrawals");
  const q = query(
    withRef,
    where("status", "==", "waiting_submit"),
    where("needManual", "==", false)
  );
  const res = await getDocs(q);

  return res.docs.map((d) => ({ ...d.data(), id: d.id }));
}
