import { useEffect, useState } from "react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { getLogs } from "../firebase";
import {
  Badge,
  Card,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import { RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { getLogsSup } from "../supaDAO";
export default function LogsPage() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getLogsSup().then((l) => setLogs(l));
  }, []);

  function LogData2({ data }) {
    return (
      <>
        {Object.entries(data).map(([key, value]) => (
          <div key={key}>
            <strong>{key}:</strong> {JSON.stringify(value).replaceAll(`"`, "")}
          </div>
        ))}
      </>
    );
  }
  return (
    <>
      <Tit2Tremor>Logs</Tit2Tremor>
      <Space />
      <Table className="mt-8 w-full">
        <TableHead>
          <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Type
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Date
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Data
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Status
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((item) => (
            <TableRow key={item.uid}>
              <TableCell>{item.type}</TableCell>
              <TableCell>
                {item.created_at.substring(0, 19).replace("T", " ")}
              </TableCell>
              <TableCell>
                <LogData2 data={item.data} />
              </TableCell>
              <TableCell>
                <Badge color={item.confirmed ? "green" : "red"}>
                  {item.confirmed ? "confirmed" : "init"}
                </Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
