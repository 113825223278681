import { useEffect, useState } from "react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { getLogs } from "../firebase";
import {
  Badge,
  Card,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import { RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import { getLogsSup, getTransactions } from "../supaDAO";
export default function TransactionPage() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getTransactions().then((l) => setLogs(l));
  }, []);

  return (
    <>
      <Tit2Tremor>Transactions</Tit2Tremor>
      <Space />
      <Table className="mt-8 w-full">
        <TableHead>
          <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Date
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Type
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Currency
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              Amount (curr)
            </TableHeaderCell>
            <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
              From
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((item) => (
            <TableRow key={item.uid}>
              <TableCell>
                {item.created_at.substring(0, 19).replace("T", " ")}
              </TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{item.currency}</TableCell>
              <TableCell>{item.amount_currency}</TableCell>
              <TableCell>{item.from}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
