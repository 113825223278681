import { useEffect, useState } from "react";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { RRow, Space, SpaceHorizontal } from "../comps/otherCOmps";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TextInput,
} from "@tremor/react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";

export function UserProfile() {
  const [uidSearch, setUidSearch] = useState("");
  const [usernameSearch, setUsernameSearch] = useState("");
  const [userData, setUserData] = useState();

  const params = useParams();
  const nav = useNavigate();

  useEffect(() => {
    console.log(params.uid);
    setUidSearch(params.uid);
    search(params.uid);
  }, []);

  async function search(uid = null) {
    if (!uidSearch && !usernameSearch && !uid) return;
    console.log(uid);

    let userDoc;
    if (uid) userDoc = await getDoc(doc(db, `users/${uid}`));
    else if (uidSearch) userDoc = await getDoc(doc(db, `users/${uidSearch}`));
    else
      userDoc = (
        await getDocs(
          query(
            collection(db, "users"),
            where("username", "==", usernameSearch)
          )
        )
      )?.docs[0];

    if (!userDoc || !userDoc.exists()) {
      nav("/users");
      setUserData(null);
      return;
    }

    nav("/users/" + userDoc.id);
    console.log(userDoc.data());
    setUserData({ ...userDoc.data(), uid: userDoc.id });
  }
  return (
    <>
      <Tit2Tremor>User profile</Tit2Tremor>
      <Space />
      <RRow width={"600px"}>
        <TextInput
          placeholder="user id"
          value={uidSearch}
          onChange={(e) => setUidSearch(e.target.value)}
        />
        <SpaceHorizontal />
        <div>
          <DescTremor>OR</DescTremor>
        </div>

        <SpaceHorizontal />
        <TextInput
          placeholder="username"
          value={usernameSearch}
          onChange={(e) => setUsernameSearch(e.target.value)}
        />
        <Button onClick={() => search(null)}>Search</Button>
      </RRow>
      <Space height="42px" />
      {!userData && <DescTremor>No user found</DescTremor>}
      {userData && (
        <>
          <Tit2Tremor>Username: {userData.username}</Tit2Tremor>
          <Tit2Tremor>ID: {userData.uid}</Tit2Tremor>
          <Table className="mt-2 w-full">
            <TableHead>
              <TableRow className="border-b border-tremor-border dark:border-dark-tremor-border">
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Currency
                </TableHeaderCell>
                <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  Amount
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(userData.balances ?? {}).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{value.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Tit2Tremor>Points: {userData.points}</Tit2Tremor>
          <DescTremor>Last login: {userData.lastLogin?.timeStamp}</DescTremor>
        </>
      )}
    </>
  );
}
